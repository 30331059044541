<template>
    <div>
        <svg
                class="svg-icon"
                width="16"
                fill="c60b38"
                style="
                    width: 1em;
                    height: 1em;
                    vertical-align: middle;
                    overflow: hidden;
                "
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M533.215 609.227c-8.956-12.502-7.819-30.005 3.412-41.236L819.47 285.148l90.51 90.51c12.497 12.497 12.497 32.758 0 45.255l-282.54 282.54 0.304 0.303-90.51 90.51c-12.496 12.496-32.757 12.496-45.254 0L96 398.285l90.51-90.51c12.496-12.497 32.758-12.497 45.255 0l301.45 301.45z"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: "ArrowDown"
}
</script>

<style>

</style>

