import axios from "axios";

var customBaseURL = 'https://mark-white.nissansouthafrica.com/api.php';
// var customBaseURL = 'https://brits.nissansouthafrica.com/api.php';

const state = {

    cars: [],
    car: {},
    allCarsList: {},

    filterData: {}

}

const getters = {

    getAllCars: (state) => state.cars,
    getSelectedCar: (state) => state.car,

    getFilterData: (state) => state.filterData,

}

const actions = {


    async fetchCars({ commit, dispatch, rootState }) {

        const response = await axios.get(customBaseURL, { params: { 'checkStatus': true } });
        // console.log(response, 'main cars data')

        var carslistData = [];
        carslistData = response.data;
        // console.log(carslistData, 'res');

        // ------ Start - Filter Search Data -------------------
        var newAllYear = [];
        var newAllMileage = [];
        var newAllPrice = [];
        // var newAllDealers = [];

        carslistData.map(elet => {

            if (!newAllYear.includes(elet.VehicleYear)) {
                newAllYear.push(parseInt(elet.VehicleYear));
            }
            if (!newAllMileage.includes(elet.VehicleMileage)) {
                newAllMileage.push(parseInt(elet.VehicleMileage));
            }
            if (!newAllPrice.includes(elet.VehiclePrice)) {
                newAllPrice.push(parseInt(elet.VehiclePrice));
            }
            // if(!newAllDealers.includes(elet.dealerId)){
            //     newAllDealers.push(elet.dealerContactInformation.name);
            // }
        });

        var yearMin = Math.min(...newAllYear);
        var yearMax = Math.max(...newAllYear);
        // console.log(yearMin + ' - ' + yearMax, 'asd Year');

        var mileageMin = Math.min(...newAllMileage);
        var mileageMax = Math.max(...newAllMileage);

        var priceMin = Math.min(...newAllPrice);
        var priceMax = Math.max(...newAllPrice);

        const allMakes = new Set(carslistData.map(ele => ele.MakeName));
        const allModels = new Set(carslistData.map(ele => ele.ModelName));
        const allBodyType = new Set(carslistData.map(ele => ele.Category));
        const allTransmission = new Set(carslistData.map(ele => ele.TransmissonType));
        const allColour = new Set(carslistData.map(ele => ele.VehicleColor));
        const allEngine = new Set(carslistData.map(ele => ele.Type));
        const allDealers = new Set(carslistData.map(ele => ele.DealershipName));

        var newfilterData = {
                allMakes: allMakes,
                allModels: allModels,
                allBodyTypes: allBodyType,
                allTransmissions: allTransmission,
                allColours: allColour,
                allEngine: allEngine,
                yearMin: yearMin,
                yearMax: yearMax,
                mileageMin: 0,
                mileageMax: mileageMax,
                priceMin: priceMin,
                priceMax: priceMax,
                allDealers: allDealers
            }
            // console.log(newfilterData, 'unique data');
            // ------ End - Filter Search Data -------------------


        commit("setCars", carslistData);
        commit("setFilterData", newfilterData);
    },

    async fetchSelectedCar({ commit, dispatch, rootState }, vehicleId) {

        const response = await axios.get(customBaseURL, { params: { 'checkStatus': true } });
        const data = response.data;

        let selected;

        Object.keys(data).forEach(key => {
            const element = data[key];

            if (element.VehicleId == vehicleId) {
                selected = element;
            }
        });

        // console.log(selected, 'single car detail');
        commit("setSelectedCar", selected);

    },

    setFilter({ commit, dispatch, rootState }, params) {

        var filter = params.inputs
        var selectedInput = params.selectedInput

        // console.log(selectedInput, 'Selected input');
        // console.log(filter, 'inner input');

        const inputs = {
            make: 'All Makes',
            model: 'All Models',
            bodyType: 'All Body',
            transmissions: 'All Transmission',
            colour: 'All Colour',
            engine: 'All Engine',
            dealer: 'All Dealers',
            priceSliderValue: [],
            mileageSliderValue: [],
            yearSliderValue: [],
            // sortByPriceValue: 'Ascending',
        }

        // console.log(filter.priceSliderValue, "priceSliderValue")

        let listcars = [];

        const cars = state.allCarsList;
        Object.keys(cars).forEach(function(key) {
            const car = cars[key];
            listcars.push(car);
        });
        // console.log(listcars, 'all cars');

        var filtercars = listcars;

        filtercars = filtercars.filter(car => {
            return (parseInt(car.VehiclePrice) >= filter.priceSliderValue[0] && parseInt(car.VehiclePrice) <= filter.priceSliderValue[1])
        })

        filtercars = filtercars.filter(car => {
            return (car.VehicleMileage >= filter.mileageSliderValue[0] && car.VehicleMileage <= filter.mileageSliderValue[1])
        })

        filtercars = filtercars.filter(car => {
            return (car.VehicleYear >= filter.yearSliderValue[0] && car.VehicleYear <= filter.yearSliderValue[1])
        })

        if (filter.make != inputs.make) {

            filtercars = filtercars.filter(car => {
                return (car.MakeName == filter.make)
            })

            const allModels2 = new Set(filtercars.map(ele => ele.ModelName));
            state.filterData.allModels = allModels2;

            const allBodyType2 = new Set(filtercars.map(ele => ele.Category));
            state.filterData.allBodyTypes = allBodyType2;

            const allColour2 = new Set(filtercars.map(ele => ele.VehicleColor));
            state.filterData.allColours = allColour2;
        }

        if (selectedInput == 'make') {

            const allModels2 = new Set(filtercars.map(ele => ele.ModelName));
            state.filterData.allModels = allModels2;

            const allBodyType2 = new Set(filtercars.map(ele => ele.Category));
            state.filterData.allBodyTypes = allBodyType2;

            const allColour2 = new Set(filtercars.map(ele => ele.VehicleColor));
            state.filterData.allColours = allColour2;
        }
        // console.log(filtercars, 'asd asd asd assd');

        if (filter.model != inputs.model) {

            filtercars = filtercars.filter(car => {
                return (car.ModelName == filter.model)
            });

            const allColour3 = new Set(filtercars.map(ele => ele.VehicleColor));
            state.filterData.allColours = allColour3;
        }

        if (selectedInput == 'model') {

            const allColour3 = new Set(filtercars.map(ele => ele.VehicleColor));
            state.filterData.allColours = allColour3;
        }


        if (filter.bodyType != inputs.bodyType) {

            filtercars = filtercars.filter(car => {
                return (car.Category == filter.bodyType)
            });
        }

        if (selectedInput == 'bodyType') {

            const allModels4 = new Set(filtercars.map(ele => ele.ModelName));
            state.filterData.allModels = allModels4;

            const allColour4 = new Set(filtercars.map(ele => ele.VehicleColor));
            state.filterData.allColours = allColour4;
        }

        if (filter.dealer != inputs.dealer) {

            filtercars = filtercars.filter(car => {
                return (car.DealershipName == filter.dealer)
            });
        }

        // console.log(filtercars)
        commit("setFilterCars", filtercars);
    },
}


const mutations = {

    setFilterCars: (state, cars) => {
        //console.log(cars, "cars");
        state.cars = cars;
    },
    setFilterData: (state, filteringData) => {
        //console.log(cars, "cars");
        state.filterData = filteringData;
    },
    setCars: (state, cars) => {
        state.cars = cars;
        state.allCarsList = cars;
    },
    setSelectedCar: (state, car) => {
        // console.log(car, "car committed");
        car.VehiclePrice = parseInt(car.VehiclePrice)
        state.car = car;
    },

};

// then need to export
export default {
    state,
    getters,
    actions,
    mutations,
};