<template>

    <div class="container">

        <div v-if="isLoading" style="position:fixed; width:100%; height:100vh; left:0; top:0; z-index:100000; background:#fff; padding:10%">
            <Loader />
        </div>

        <h1 class="mt-3">NISSAN CERTIFIED PRE-OWNED VEHICLES</h1>

        <FilerPanel />

        <div class="main-heading mt-5 text-start">
            <span> {{ getAllCars.length }} VEHICLES FOUND</span>
        </div>

        <section class="results mt-6">
            <div class="row results__card" v-for="(car, index) in getAllCars" :key="index">
                <div class="col-lg-3">
                    <div class="image__wrapper">

                        <div v-if="car.IMAGE_URLS[0]">
                            <img 
                                v-lazy="{ 
                                    src: car.IMAGE_URLS[0], 
                                    loading: '/Loading_icon.gif', 
                                    error: '/noimg.jpg', 
                                    delay: 500 
                                }" 
                                :alt="car.make"  
                            />
                        </div>
                        <div v-else>
                            <img src="/noimg.jpg" alt="Nissan" />
                        </div>

                    </div>
                </div>
                <div class="col-lg-6">
                    <h3 class="title">{{car.MakeName}} {{car.ModelName}} {{car.VariantName}}</h3>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="list">
                                <div class="list__items d-flex">
                                    <div class="list__item-val">Mileage:</div>
                                    <div class="list__item-val">{{car.VehicleMileage}} KM </div>
                                </div>
                                <div class="list__items d-flex">
                                    <div class="list__item-val">Model year:</div>
                                    <div class="list__item-val">{{car.VehicleYear}}</div>
                                </div>
                                <!-- <div class="list__items d-flex">
                                    <div class="list__item-val">Fuel type:</div>
                                    <div class="list__item-val">{{car.fuelType}}</div>
                                </div>
                                <div class="list__items d-flex">
                                    <div class="list__item-val">Engine Capacity CC:</div>
                                    <div class="list__item-val">{{car.engineCapacityInCc}}</div>
                                </div> -->
                                <div class="list__items d-flex">
                                    <div class="list__item-val">Body Style:</div>
                                    <div class="list__item-val">{{car.Category}}</div>
                                </div>
                                <!-- <div class="list__items d-flex">
                                    <div class="list__item-val">Transmission:</div>
                                    <div class="list__item-val">{{ car.transmission }}</div>
                                </div> -->
                                <div class="list__items d-flex">
                                    <div class="list__item-val">Exterior Color:</div>
                                    <div class="list__item-val">{{car.VehicleColor}}</div>
                                </div>
                                <div class="list__items d-flex">
                                    <div class="list__item-val">Stock Number:</div>
                                    <div class="list__item-val">{{car.StockCode}}</div>
                                </div>
                                <!-- <div class="list__items d-flex">
                                    <div class="list__item-val">Dealer Id:</div>
                                    <div class="list__item-val">{{car.dealerId}}</div>
                                </div> -->
                                <div class="list__items d-flex">
                                    <div class="list__item-val">Dealer Name:</div>
                                    <div class="list__item-val">{{car.DealershipName}}</div>
                                </div>
                                <!-- <div class="list__items d-flex">
                                    <div class="list__item-val">VIN:</div>
                                    <div class="list__item-val">{{ car.VIN }}</div>
                                </div> -->
                                <!-- <div class="list__items d-flex">
                                    <div class="list__item-val">Comment:</div>
                                    <div class="list__item-val">{{car.Comments}}</div>
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="col-lg-12">
                            <div class="address">
                                <div class="address__title">GLENDALE NISSAN</div>
                                    <div class="address__actual">484 NORTH AVE GLENDALE HEIGHTS, IL 60139</div>
                                    <div class="phone__number">630-469-6100</div>
                                    <div class="link">Nissan Certified Pre-Owned</div>
                                    <div class="link__sticker">Original Window Sticker</div>
                                    <div class="sticker">
                                    <img
                                        src="https://picsum.photos/100"
                                        class="h-100 w-100"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-3">
                    <!-- <div class="price__cancelled" v-if=" Vehicle.WasPrice>0">
                        <s>R {{ car.WasPrice }}</s>
                    </div> -->
                    <div class="price__actual">
                        R {{ Number(parseInt(car.VehiclePrice)).toLocaleString() }}
                    </div>
                    <button @click="viewVehicleDetail(car)" class="results__cta d-block">Vehicle details</button>

                </div>
            </div>
        </section>

    </div>

</template>

<script>

import Loader from '../components/Cars/UI/Loader';
import FilerPanel from "../components/Cars/FilerPanel.vue";

import { mapGetters, mapActions } from "vuex";
import { adobeAnalyticCode } from "../analytics.js";
import axios from 'axios';

export default {
    name: "Cars",
    props: {

    },
    components: {
        FilerPanel,
        Loader
    },
    computed: {

        ...mapGetters(["getAllCars"]),
    },
    mounted() {

        setTimeout(() => {

            // console.log(this.getAllCars, 'checking')
            if(Object.values(this.getAllCars).length !== 0){
                this.isLoading = false;
            }

        }, 1000);
    },
    created() {

        this.fetchCars({});
    },
    data() {
        return {
            isLoading: true
        }
    },
    methods: {

        ...mapActions(["fetchCars"]),

        viewVehicleDetail (car) {

            // console.log(car, 'asd');
            this.$router.push('/details/'+car.VehicleId);

            adobeAnalyticCode({
                'field_type': '',
                'category': car.Category, //BodyType
                'make': car.MakeName,
                'model': car.ModelName,
                'vehicleid': car.VehicleId,
                'language': "en",
                'version_name': car.MakeName +' '+ car.ModelName + ' ' + car.VariantName,
            });

        }
    }
}
</script>

<style>
    @import "../assets/custom.css";
</style>