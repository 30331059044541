<template>
    <div class="thankyouWrapper">
        <div class="container">
            <div class="row">
                <img src="@/assets/img/smile.png" />
                <h4>Thank You</h4>
                <p>Nissan team will contact shortly </p>

            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.thankyouWrapper img{
    width: 155px;
    margin: 0 auto;
}
.thankyouWrapper h4{
    font-size: 3em;
    margin-top: 1em;
}
</style>