import Vuex from 'vuex';
import cars from './modules/cars.js';

// Load Vuex
// Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        cars
    }
});