import { createRouter, createWebHistory } from 'vue-router'

import Car from '../views/Cars.vue'
import CarView from '../views/CarView.vue'
import ThankYou from '../views/ThankYou.vue'

const routes = [
    {
        path: '/',
        name: 'Cars',
        component: Car
    },
    {
        path: '/details/:vehicleId',
        name: 'CarView',
        component: CarView
    },
    { 
        path: '/thankyou', 
        name: 'thankyou', 
        component: ThankYou 
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router